import { Form, Select, Row, Col, Input, Button, Spin } from "antd"
import { debounce, values } from "lodash"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { getListProductForSPAdmin } from "../../../../../services/product"
import { getListCompany } from "../../../../../services/superAdManageCompany"
import "../style.scss"

const FormSearch = ({
  debounceTimeout = 500,
  collections,
  dataOrigin,
  listProductSelected,
  setListProductSelected,
  isNeedSelect,
  setIsNeedSelect,
}) => {
  const [collectionsMedium, setCollectionsMedium] = useState(null)
  const [collectionsSmall, setCollectionsSmall] = useState(null)
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [listCompany, setListCompany] = useState([])
  const fetchRef = useRef(0)
  const [searchParams, setSearchParams] = useState(null)
  const [haveMore, setHaveMore] = useState(true)
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const [tableParamsProduct, setTableParamsProduct] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })
  const [isLoadingFilter, setIsLoadingFilter] = useState(false)
  const [listProduct, setListProduct] = useState(null)
  const [valueFilter, setValueFilter] = useState({})
  const [haveMoreProduct, setHaveMoreProduct] = useState(true)

  const handlegetListCompany = async () => {
    setIsLoading(true)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      is_have_products: true,
      q: searchParams ? searchParams : null,
    }
    const { response } = await getListCompany(dataSent)
    if (response.status == 200) {
      setListCompany(response.data.stores)
      if (response.data.stores?.length < 10) {
        setHaveMore(false)
      }
      if (response.data.stores?.length == 10) {
        setHaveMore(true)
      }
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handlegetListCompany()
  }, [searchParams])

  const handlegetListProduct = async (value) => {
    setIsLoadingFilter(true)
    const dataSent = {
      offset:
        (tableParamsProduct.pagination.current - 1) *
        tableParamsProduct.pagination?.pageSize,
      limit: tableParamsProduct.pagination.pageSize,
      q: valueFilter.keyword,
      tags: valueFilter.origin?.length > 0 ? valueFilter.origin : null,
      collection_id: valueFilter.small
        ? [valueFilter.small]
        : valueFilter.middle
        ? [valueFilter.middle]
        : valueFilter.max
        ? [valueFilter.max]
        : null,
      store_id: valueFilter.company?.length > 0 ? valueFilter.company : null,
    }
    const { response } = await getListProductForSPAdmin(dataSent)
    if (response.status == 200) {
      setListProduct(response.data.products)

      setIsLoadingFilter(false)
    }
  }
  useEffect(() => {
    handlegetListProduct()
  }, [valueFilter])

  const handleChangeSelectMax = (e) => {
    console.log()
    const arrayMedium = collections.find((col) => col.id == e)
    form.resetFields(["middle", "small"])
    setCollectionsSmall(null)
    setCollectionsMedium(arrayMedium ? arrayMedium.children : "")
  }
  const handleChangeSelectMedium = (e) => {
    const arraySmall = collectionsMedium.find((col) => col.id == e)
    form.setFieldsValue({ ["small"]: null })

    setCollectionsSmall(arraySmall ? arraySmall.children : "")
  }

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return
      }
      setListCompany([])
      tableParams.pagination.current = 1
      setTableParams({ ...tableParams })
      setSearchParams(value)
    }
    return debounce(loadOptions, debounceTimeout)
  }, [handlegetListCompany, debounceTimeout])

  const onScroll = async (event) => {
    if (!haveMore) {
      return
    }
    const target = event.target
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      console.log("load")

      target.scrollTo(0, target.scrollHeight)

      tableParams.pagination.current = tableParams.pagination.current + 1
      setIsLoading(true)

      setTableParams({ ...tableParams })

      const dataSent = {
        offset:
          (tableParams.pagination.current - 1) *
          tableParams.pagination?.pageSize,
        limit: tableParams.pagination.pageSize,
        is_have_products: true,

        q: searchParams ? searchParams : null,
      }
      const { response } = await getListCompany(dataSent)
      if (response.status == 200) {
        if (response.data.stores?.length < 10) {
          setHaveMore(false)
        }
        if (response.data.stores?.length == 10) {
          setHaveMore(true)
        }

        setListCompany([...listCompany, ...response.data.stores])
        setIsLoading(false)
      }
    }
  }
  const onScrollProduct = async (event) => {
    if (!haveMoreProduct) {
      return
    }
    const target = event.target
    if (
      !isLoadingFilter &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      console.log("load")

      target.scrollTo(0, target.scrollHeight)

      tableParamsProduct.pagination.current++
      setIsLoadingFilter(true)

      setTableParamsProduct({ ...tableParamsProduct })
      const listProduct2 = [...listProduct]
      const dataSent = {
        offset:
          (tableParamsProduct.pagination.current - 1) *
          tableParamsProduct.pagination?.pageSize,
        limit: tableParamsProduct.pagination.pageSize,
        q: valueFilter.keyword,
        tags: valueFilter.origin?.length > 0 ? valueFilter.origin : null,
        collection_id: valueFilter.small
          ? [valueFilter.small]
          : valueFilter.middle
          ? [valueFilter.middle]
          : valueFilter.max
          ? [valueFilter.max]
          : null,
        store_id: valueFilter.company?.length > 0 ? valueFilter.company : null,
      }
      const { response } = await getListProductForSPAdmin(dataSent)
      if (response.status == 200) {
        if (response.data?.products?.length < 2) {
          setHaveMoreProduct(false)
        }
        if (response.data?.products?.length == 2) {
          setHaveMoreProduct(true)
        }

        setListProduct([...listProduct2, ...response.data.products], () => {
          setIsLoadingFilter(false)
        })
      }
    }
  }

  const onFinish = (value) => {
    console.log("valuevaluevalue", value)
    setValueFilter(value)
  }
  return (
    <div>
      <div className="formSearch mt-[10px]">
        <Form
          onFinish={onFinish}
          labelCol={{ span: 4 }}
          colon={false}
          labelAlign="left"
          form={form}
          // className='className'
          wrapperCol={{ span: 20 }}
        >
          <div className="pl-[25px]">
            <Form.Item label={<div className="myLabel">{"カテゴリ"}</div>}>
              <div className="flex items-center fix_ant_item_color_plaholder">
                <Form.Item name="max">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="大カテゴリ"
                    onChange={handleChangeSelectMax}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {collections &&
                      collections.map((great) => (
                        <Select.Option value={great.id} key={great.id}>
                          {great.title}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="middle" className="!mx-[10px]">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="中カテゴリ"
                    onChange={handleChangeSelectMedium}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {collectionsMedium &&
                      collectionsMedium.map((great) => (
                        <Select.Option value={great.id} key={great.id}>
                          {great.title}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="small">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="小カテゴリ"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {collectionsSmall &&
                      collectionsSmall.map((great) => (
                        <Select.Option value={great.id} key={great.id}>
                          {great.title}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label={<div className="myLabel">{"産地"}</div>}>
              <Form.Item name="origin">
                <Select
                  mode="multiple"
                  allowClear
                  className="fix_antd_remove_selection"
                  placeholder="産地を選択してください"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {dataOrigin &&
                    dataOrigin.map((great) => (
                      <Select.Option value={great.id} key={great.id}>
                        {great.value}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item label={<div className="myLabel">{"出品企業"}</div>}>
              <Form.Item name="company">
                <Select
                  mode="multiple"
                  allowClear
                  className="fix_antd_remove_selection"
                  placeholder="出品企業を選択してください"
                  filterOption={false}
                  onSearch={debounceFetcher}
                  onPopupScroll={onScroll}
                  onBlur={() => {
                    tableParams.pagination.current = 1
                    setTableParams({ ...tableParams })
                    setSearchParams(null)
                  }}
                >
                  {isLoading
                    ? [
                        ...listCompany.map((great) => (
                          <Select.Option value={great.store_id} key={great.id}>
                            {great.name}
                          </Select.Option>
                        )),
                        <Select.Option key="loading" disabled={true}>
                          <Spin size="small" />
                        </Select.Option>,
                      ]
                    : listCompany.map((great) => (
                        <Select.Option value={great.store_id} key={great.id}>
                          {great.name}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item label={<div className="myLabel">{"キーワード"}</div>}>
              <Form.Item name="keyword">
                <Input placeholder="商品名を記入してください" />
              </Form.Item>
            </Form.Item>

            <div className="flex justify-center">
              <Button
                htmlType="submit"
                danger
                className="!text-13 font-medium font-notoSans flex items-center !w-[131px] !h-[37px]"
                loading={isLoadingFilter}
              >
                商品を検索する
              </Button>
            </div>
            <div className="text-[12px] pt-3 text-center">
              商品を検索するボタンをクリック後、対象商品を選択でお選びください。
            </div>
          </div>
        </Form>
      </div>
      {listProduct && (
        <div className="formSearch border-t border-[#E0E0E0] w- mt-[20px] pt-[20px] ml-[25px]">
          <div className="myLabel pb-2">対象商品を選択</div>
          <Select
            mode="multiple"
            allowClear
            labelInValue
            value={listProductSelected ? listProductSelected : []}
            className="fix_antd_remove_selection w-full"
            placeholder="出品企業を選択してください"
            onChange={(e) => {
              setIsNeedSelect(false)
              setListProductSelected(e)
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onPopupScroll={onScrollProduct}
          >
            {isLoadingFilter
              ? [
                  ...listProduct.map((great) => (
                    <Select.Option value={great.id} key={great.id}>
                      {great.title}
                    </Select.Option>
                  )),
                  <Select.Option key="loading" disabled={true}>
                    <Spin size="small" />
                  </Select.Option>,
                ]
              : listProduct.map((great) => (
                  <Select.Option value={great.id} key={great.id}>
                    {great.title}
                  </Select.Option>
                ))}
          </Select>
          {isNeedSelect && (
            <div className="ant-form-item-explain-error">対象商品を選択。</div>
          )}
        </div>
      )}
    </div>
  )
}

export default FormSearch
