import { Typography } from "antd"
import React, { useEffect } from "react"
import TableData from "./TableData"

const { Text } = Typography

const CampaignList = ({ keyPage }) => {
  return (
    <div className="bg-grey-0 p-[40px]">
      <div className="flex flex-col text-sm font-medium text-[#555] tracking-4 font-notoSans">
        <div className="font-[500]">
          取引可能な会員に向けて割引設定をする機能です。
          <br />
          期間限定の割引キャンペーンを実施したり、特定の会員にむけて優待価格を設定することができます。
          <br />
          予約できるキャンペーンの上限は、実施中のものを含め10件です。
        </div>
        <Text className="!text-[#555] mt-[30px]">
          ※もとの卸単価を上げてキャンペーンを実施する行為を禁止します（このような行為は、独占禁止法に抵触します）。
        </Text>
        <Text className="!text-[#555]">
          ※全会員向けに無期限の設定はできません。
          <Text className="!text-primary-90">商品管理</Text>
          にて卸価格を変更してください。
        </Text>
      </div>
      <TableData />
    </div>
  )
}

export default CampaignList
