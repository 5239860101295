import request from "../customUtil/request"

export async function getListZoom(param) {
  return request("/admin/super/zoom", {
    method: "GET",
    params: param,
  })
}

export async function createZoom(data) {
  return request(`/admin/zip`, {
    method: "POST",
    data: data,
  })
}

export async function addZoom(data) {
  return request(`/admin/super/zoom`, {
    method: "POST",
    data,
  })
}

export async function delteZoom(params) {
  return request(`/admin/super/zoom`, {
    method: "DELETE",
    params,
  })
}
