import { Button, Popconfirm } from "antd"
import React, { useContext, useState } from "react"
import { SuperAdSiteContext } from "../.."
import { openCustomNotificationWithIcon } from "../../../../common/notifycation"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../../components/notification"
import { createCampagin, deleteCampaign } from "../../../../services/campaign"
import textAndRules from "../../../../utils/textAndRules"

const FooterHandle = ({
  dataCampaign,
  step,
  setStep,
  setDraftStatus,
  isLoading,
  form,
}) => {
  const { setCampaignType } = useContext(SuperAdSiteContext)

  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isLoadingDub, setIsLoadingDub] = useState(false)

  return (
    <div className="flex items-center justify-center child:mr-[10px] child:!h-[37px] mt-[50px]">
      <Button
        className="!bg-[#E4E8EB] !w-[105px] !text-13 font-notoSans !text-black-5"
        onClick={() => setCampaignType("list")}
      >
        一覧に戻る
      </Button>
      {step == 1 && (
        <Button
          className="!w-[176px] shadow-button"
          type="primary"
          onClick={() => {
            setStep(2)
          }}
        >
          編集
        </Button>
      )}
      {step == 2 && (
        <Button
          danger
          style={{ width: 176 }}
          className="!h-[37px] mr-[10px]"
          loading={isLoading}
          onClick={() => {
            setDraftStatus(null)

            form.submit()
          }}
        >
          {"下書き保存する"}
        </Button>
      )}
      {step == 2 && (
        <Button
          type="primary"
          loading={isLoading}
          htmlType="submit"
          onClick={() => {
            setDraftStatus(1)

            form.submit()
          }}
          style={{ width: 176 }}
          className=" !h-[37px]"
        >
          保存する
        </Button>
      )}
      <Popconfirm
        placement="bottomLeft"
        title={
          <div style={{ fontSize: 14, fontWeight: 600 }}>
            {`本当に実行しますか ?`}
          </div>
        }
        onConfirm={async () => {
          setIsLoadingDelete(true)
          const { response } = await deleteCampaign({
            discountIds: [dataCampaign.id],
          })
          if (response.status == 200) {
            setIsLoadingDelete(false)

            openNotificationChangeSuccess(
              textAndRules.updateSuccess,
              "",
              "#389e0d"
            )
            setCampaignType("list")
          } else {
            openNotificationFail(
              "ただいま、サーバが混み合っています。",
              "しばらくしてから再度操作してください。",
              "#f81d22"
            )
          }
        }}
        okText="削除"
        cancelText="キャンセル"
      >
        <Button danger className="!w-[105px]" loading={isLoadingDelete}>
          削除
        </Button>
      </Popconfirm>

      {step == 1 && (
        <Button
          type="danger"
          className="!w-[105px]"
          loading={isLoadingDub}
          onClick={async () => {
            setIsLoadingDub(true)
            const dataSent = {
              code: dataCampaign.code,
              is_disabled: true,
              rule: {
                allocation: "total",
                type: "percentage",
                value: dataCampaign?.rule?.value,
                description: dataCampaign?.rule?.description,
                conditions: !dataCampaign?.metadata?.products
                  ? null
                  : [
                      {
                        operator: "in",
                        products: dataCampaign?.metadata?.products?.map(
                          (e) => e.value
                        ),
                      },
                    ],
              },
              ends_at: dataCampaign.ends_at,
              starts_at: dataCampaign.starts_at,
              metadata: {
                url: dataCampaign.metadata?.url,
                products: dataCampaign?.metadata?.products
                  ? dataCampaign?.metadata?.products
                  : null,
              },
            }
            const { response } = await createCampagin(dataSent)
            if (response.status == 200) {
              openCustomNotificationWithIcon(
                "success",
                "キャンペーン登録成でした。",
                ""
              )
              setCampaignType("list")
            } else {
              openNotificationFail(
                "ただいま、サーバが混み合っています。",
                "しばらくしてから再度操作してください。",
                "#f81d22"
              )
              setIsLoadingDub(false)
            }
          }}
        >
          コピー作成
        </Button>
      )}
    </div>
  )
}

export default FooterHandle
