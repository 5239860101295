import { Col, Form, Input, Row } from "antd"
import React from "react"
import { TableLeft } from "."
import textAndRules from "../../../../utils/textAndRules"

const CatchCopy = () => {
  return (
    <Row className="!flex border-t border-custom-border">
      <TableLeft tag="必須" type="red" title="キャッチコピー" />
      <Col className="p-5 pr-[159px] flex-1">
        <Form.Item
          name="catchCopy"
          rules={[
            {
              required: true,
              message: textAndRules.pleaseInput,
            },
            {
              whitespace: true,
              message: "空白を入力できません。",
            },
          ]}
        >
          <Input
            placeholder="※全角または半角25字以内"
            showCount
            maxLength={25}
            className="uppercase "
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default CatchCopy
