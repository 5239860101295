import {
  Button,
  Image,
  Modal,
  Space,
  Typography,
  Upload,
  Form,
  Input,
} from "antd"
import ImgCrop from "antd-img-crop"
import upIcon from "../../../images/upIcon.svg"
import downIcon from "../../../images/downIcon.svg"
import closeIcon from "../../../images/closeIcon.svg"
import imageIcon from "../../../images/icon_image.svg"
import React, { useState } from "react"

import "./style.scss"
import {
  deleteBanner,
  postBanner,
  updateBanner,
  uploads,
} from "../../../services/user"
import { Link } from "gatsby"
import {
  openNotificationFail,
  openNotificationWarning,
} from "../../../components/notification"

const { Text } = Typography

const UploadBanner = ({
  banners,
  id,
  index,
  length,
  image,
  linkToUrl,
  setBanners,
  setLoading,
}) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [fileList, setFileList] = useState([])
  const [pass, setPass] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  const beforeUpload = (file, info) => {
    console.log("file", file)
    const isJPG = file.type === "image/jpeg"
    const isJPEG = file.type === "image/jpeg"
    const isGIF = file.type === "image/gif"
    const isPNG = file.type === "image/png"
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      openNotificationWarning(
        "間違ったファイル形式。",
        `JPG、JPEG、GIF、PNGファイルをアップロードしてください。`,
        "#f81d22"
      )

      setPass(false)
      return false
    }
    setPass(true)
    return true
  }

  const handleDown = async () => {
    if (index < length) {
      setLoading(true)
      updateBanner({
        id,
        priority: index + 1,
      })
        .then(() => {
          updateBanner({
            id: banners[index + 1].id,
            priority: index,
          })
            .then(() => {
              setBanners((prev) => {
                const newData = [...prev]
                const temp = newData[index]
                newData[index] = newData[index + 1]
                newData[index + 1] = temp
                return newData
              })
              setLoading(false)
            })
            .catch((error) => console.log(error))
        })
        .catch((error) => console.log(error))
    }
  }
  const normFile = (e) => {
    console.log("Upload event:", e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleUp = async () => {
    if (index !== 0) {
      setLoading(true)
      updateBanner({
        id,
        priority: index - 1,
      })
        .then(() => {
          updateBanner({
            id: banners[index - 1].id,
            priority: index,
          })
            .then(() => {
              setBanners((prev) => {
                const newData = [...prev]
                const temp = newData[index]
                newData[index] = newData[index - 1]
                newData[index - 1] = temp
                return newData
              })
              setLoading(false)
            })
            .catch((error) => console.log(error))
        })
        .catch((error) => console.log(error))
    }
  }

  const handleDelete = async () => {
    setVisibleModal(true)
  }

  const handleOkModalRemove = async () => {
    try {
      setLoading(true)
      const { response } = await deleteBanner({ id })
      if (response.data) {
        setBanners((prev) => prev.filter((_, i) => i !== index))
      }
      setLoading(false)
      setVisibleModal(false)
    } catch (error) {
      console.log(error)
      setVisibleModal(false)
    }
  }

  const handleCancelModalRemove = () => {
    setVisibleModal(false)
  }

  const handleChange = async (info) => {
    console.log("infoinfo", info)
    let fileList = [...info.fileList]

    if (fileList.length == 0) {
      setFileList([...fileList])
    }
    if (pass) {
      fileList = fileList.slice(-1)
      setFileList(fileList)
    }
  }

  const uploadImageF = async (options) => {
    console.log("options", options)
    const { onSuccess, onError, file, onProgress, category } = options

    const fmData = new FormData()

    fmData.append("files", file)
    try {
      const { response } = await uploads(fmData)
      console.log("resresresres", response)

      if (response?.status == 200) {
        onSuccess("ok", response)

        form.setFieldsValue({ urlImage: response.data.uploads[0]?.url })
      } else {
        form.setFieldsValue({ urlImage: null })
        onError("fail")
        setFileList([])
      }
    } catch (err) {
      console.log("Eroor: ", err)
      form.setFieldsValue({ urlImage: null })

      onError({ err })
    }
  }

  const checkFileList = (_, value) => {
    console.log("value", value)

    if (Array.isArray(value)) {
      return Promise.reject(new Error("写真はまだアップロードされていません。"))
    }
    if (typeof value == "string") {
      return Promise.resolve()
    }

    return Promise.reject(new Error("画像を選択してください。"))
  }

  const onFinish = async (values) => {
    // setCampaignType("list")
    console.log("valuesvalues", values)
    setIsLoading(true)

    const { response } = await await postBanner({
      priority: 0,
      bannerUrl: values?.urlImage,
      linkToUrl: values?.linkToUrl,
    })

    if (response.status == 200) {
      setIsLoading(false)

      setBanners((prev) => {
        const newData = [...prev]
        newData[index] = response.data.data
        return [...newData]
      })
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="flex items-center border-b border-[rgba(0,0,0,0.06)] pb-[30px] mb-[30px]">
        <Text className="w-[141px] text-[15px] font-medium font-notoSans !text-grey-4">
          MV画像{index + 1}
        </Text>
        {image ? (
          <div className="flex-1 pr-7 customImg">
            <Image
              alt=""
              src={image}
              className="!h-[166px] !w-full object-cover"
            />
            <Space direction="horizontal">
              <div>{`遷移先URL: `}</div>
              <Link to={linkToUrl ? linkToUrl : null}>{`${
                linkToUrl ? linkToUrl : "なし。"
              }`}</Link>
            </Space>
          </div>
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
            className="flex-1 flex flex-col  customUpload relative"
          >
            <Form.Item
              name={"urlImage"}
              getValueFromEvent={normFile}
              rules={[
                {
                  validator: checkFileList,
                },
              ]}
            >
              <Space>
                <ImgCrop
                  aspect={13 / 3}
                  grid
                  rotate
                  modalTitle="画像編集"
                  modalOk="選択"
                  modalCancel="キャンセル"
                >
                  <Upload
                    customRequest={uploadImageF}
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    maxCount={1}
                    listType="picture"
                    onRemove={(e) => {
                      form.setFieldsValue({ urlImage: null })
                    }}
                    withCredentials={true}
                  >
                    <Button
                      danger
                      icon={
                        <Image
                          alt=""
                          src={imageIcon}
                          preview={false}
                          className="pr-2"
                        />
                      }
                      className="!text-primary-90 !flex items-center !w-[175px] !h-[37px]"
                    >
                      画像をアップロード
                    </Button>
                  </Upload>
                </ImgCrop>
                <Text className="!text-custom-5 text-sm font-notoSans font-medium ml-[10px]">
                  選択されていません
                </Text>
              </Space>
            </Form.Item>
            <Form.Item
              name={"linkToUrl"}
              rules={[{ type: "url" }]}
              label={"遷移先URL"}
            >
              <Input placeholder={"URLを記入する"} style={{ width: "60%" }} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 176 }}
                className=" !h-[37px]"
                loading={isLoading}
              >
                保存する
              </Button>
            </Form.Item>
          </Form>
        )}

        {image && (
          <div
            className="flex items-center border-l border-[rgba(204,204,204,0.5)] pl-4 
        child:border child:border-primary-90 child:rounded-sm child:w-[25px] 
              child:h-[25px] child:flex child:items-center child:justify-center h-8"
          >
            <button onClick={handleDown}>
              <img src={downIcon} alt="" />
            </button>
            <button className="mx-[10px]" onClick={handleUp}>
              <img src={upIcon} alt="" />
            </button>
            <button onClick={handleDelete}>
              <img src={closeIcon} alt="" />
            </button>
          </div>
        )}
      </div>
      <Modal
        title="この画像を削除してもよろしいでしょうか？"
        okText="削除"
        cancelText="キャンセル"
        centered
        visible={visibleModal}
        onOk={handleOkModalRemove}
        onCancel={handleCancelModalRemove}
      />
    </>
  )
}

export default UploadBanner
