import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, DatePicker, Form, Input, Select, Spin } from "antd"
import "./style.scss"
import moment from "moment"
import { addZoom } from "../../../services/zoom"
import { openNotificationFail } from "../../../components/notification"
import { debounce } from "lodash"
import { getListCompany } from "../../../services/superAdManageCompany"
import textAndRules from "../../../utils/textAndRules"

const disabledDate = (current) => {
  return current && current < moment().startOf("day")
}

const AddNewZoomLive = ({ getListLinkZoom, debounceTimeout = 500 }) => {
  const ref = useRef()
  const [form] = Form.useForm()
  const [haveMore, setHaveMore] = useState(true)
  const fetchRef = useRef(0)
  const [listCompany, setListCompany] = useState([])
  const [searchParams, setSearchParams] = useState(null)

  const [selectedDate, setSelectedDate] = useState(null)
  const [isHaveDisableTime, setIsHaveDisableTime] = useState(false)
  const currentHour = moment().format("HH")

  useEffect(() => {
    if (selectedDate) {
      const selectedDateFomat = moment(selectedDate).format("L")
      const currentDate = moment().format("L")
      if (moment(selectedDateFomat).isBefore(moment(currentDate))) {
        setIsHaveDisableTime(false)
      } else if (moment(selectedDateFomat).isAfter(moment(currentDate))) {
        setIsHaveDisableTime(false)
      } else {
        setIsHaveDisableTime(true)
      }
    } else {
      setIsHaveDisableTime(false)
    }
  }, [selectedDate])

  const range = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  const disabledDateTime = () => ({
    disabledHours: () => {
      if (isHaveDisableTime) {
        return range(0, 24).splice(0, Number(currentHour))
      }
    },
  })

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, {
      block: "center",
      scrollMode: "center",
      behavior: "smooth",
    })
  }

  const handlegetListCompany = async () => {
    setIsLoading(true)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      q: searchParams ? searchParams : null,
    }
    const { response } = await getListCompany(dataSent)
    if (response.status == 200) {
      setListCompany(response.data.stores)
      if (response.data.stores?.length < 10) {
        setHaveMore(false)
      }
      if (response.data.stores?.length == 10) {
        setHaveMore(true)
      }
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handlegetListCompany()
  }, [searchParams])
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return
      }
      setListCompany([])
      tableParams.pagination.current = 1
      setTableParams({ ...tableParams })
      setSearchParams(value)
    }
    return debounce(loadOptions, debounceTimeout)
  }, [handlegetListCompany, debounceTimeout])

  const onScroll = async (event) => {
    if (!haveMore) {
      return
    }
    const target = event.target
    if (
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      console.log("load")

      target.scrollTo(0, target.scrollHeight)

      tableParams.pagination.current = tableParams.pagination.current + 1
      setIsLoading(true)

      setTableParams({ ...tableParams })

      const dataSent = {
        offset:
          (tableParams.pagination.current - 1) *
          tableParams.pagination?.pageSize,
        limit: tableParams.pagination.pageSize,
        q: searchParams ? searchParams : null,
      }
      const { response } = await getListCompany(dataSent)
      if (response.status == 200) {
        if (response.data.stores?.length < 10) {
          setHaveMore(false)
        }
        if (response.data.stores?.length == 10) {
          setHaveMore(true)
        }

        setListCompany([...listCompany, ...response.data.stores])
        setIsLoading(false)
      }
    }
  }
  const onFinish = async (values) => {
    setLoading(true)
    const res = await addZoom({
      ...values,
    })
    setLoading(false)
    if (res.data) {
      form.resetFields()
      getListLinkZoom()
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "red"
      )
    }
  }
  return (
    <div className=" px-12 ">
      <Form
        colon={false}
        className="flex flex-col w-full px-[80px] items-center fix_ant_item"
        name="basic"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 19 }}
        labelAlign="left"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        ref={ref}
      >
        <Form.Item
          label={
            <div className="flex gap-[14px]">
              {
                <div className="bg-[#FFF1F0] text-[#E75B53] text-[11px] rounded-[5px] p-[3px]">
                  {"必須"}
                </div>
              }
              <div className=" text-[14px]">{"開催日の登録"}</div>
            </div>
          }
        >
          <Form.Item
            name={"date"}
            rules={[
              {
                required: true,
                message: textAndRules.pleaseSelect,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const selectedDateFomatFieldValue = moment(value)
                  if (moment(selectedDateFomatFieldValue).isBefore(moment())) {
                    return Promise.reject(
                      new Error(`現在時刻から選択してください。`)
                    )
                  } else {
                    return Promise.resolve()
                  }
                },
              }),
            ]}
          >
            <DatePicker
              disabledDate={disabledDate}
              onSelect={setSelectedDate}
              format="YYYY-MM-DD HH:mm"
              minuteStep={5}
              disabledTime={disabledDateTime}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: isHaveDisableTime
                  ? moment(`${currentHour}:00:00`, "HH:mm")
                  : moment("00:00:00", "HH:mm"),
              }}
            />
          </Form.Item>
        </Form.Item>
        <div className="line_full_width"></div>

        <Form.Item
          label={
            <div className="flex gap-[14px]">
              {
                <div className="bg-[#FFF1F0] text-[#E75B53] text-[11px] rounded-[5px] p-[3px]">
                  {"必須"}
                </div>
              }
              <div className=" text-[14px]">{"企業名"}</div>
            </div>
          }
        >
          <Form.Item
            name={"company"}
            rules={[
              {
                required: true,
                message: textAndRules.pleaseSelect,
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              className="fix_antd_remove_selection"
              placeholder="出品企業を選択してください"
              filterOption={false}
              onSearch={debounceFetcher}
              onPopupScroll={onScroll}
              onBlur={() => {
                tableParams.pagination.current = 1
                setTableParams({ ...tableParams })
                setSearchParams(null)
              }}
            >
              {isLoading
                ? [
                    ...listCompany.map((great) => (
                      <Select.Option value={great.store_id} key={great.id}>
                        {great.name}
                      </Select.Option>
                    )),
                    <Select.Option key="loading" disabled={true}>
                      <Spin size="small" />
                    </Select.Option>,
                  ]
                : listCompany.map((great) => (
                    <Select.Option value={great.store_id} key={great.id}>
                      {great.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Form.Item>
        {/* <div className="line_full_width"></div>
        <Form.Item
          label={
            <div className="flex gap-[14px]">
              {
                <div className="bg-[#FFF1F0] text-[#E75B53] text-[11px] rounded-[5px] p-[3px]">
                  {"必須"}
                </div>
              }
              <div className=" text-[14px]">{"開催時のURL登録"}</div>
            </div>
          }
        >
          <Form.Item
            name={"url"}
            rules={[
              {
                required: true,
                message: `開催時のURL登録は必須項目です。入力してください。`,
              },
            ]}
          >
            <Input placeholder={"https://us02web.zoom.us/j/00000000001"} />
          </Form.Item>
        </Form.Item> */}

        <div className="line_full_width"></div>
        <Form.Item
          label={
            <div className="flex gap-[14px]">
              {
                // <div className="bg-[#E4E8EB]  text-[11px] rounded-[5px] p-[3px]">
                //   {"任意"}
                // </div>
                <div className="bg-[#FFF1F0] text-[#E75B53] text-[11px] rounded-[5px] p-[3px]">
                  {"必須"}
                </div>
              }
              <div className=" text-[14px]">{"タイトル"}</div>
            </div>
          }
        >
          <Form.Item
            name={"name"}
            rules={[
              {
                required: true,
                message: textAndRules.pleaseInput,
              },
              {
                whitespace: true,
                message: "空白を入力できません。",
              },
            ]}
          >
            <Input placeholder={"タイトルを記入してください。"} />
          </Form.Item>
        </Form.Item>
        <div className="line_full_width"></div>
        <Button
          type="primary"
          className="text-[#fff] rounded-[5px]   w-[320px] inter-base-regular"
          size="large"
          htmlType="submit"
          loading={loading}
        >
          登録する
        </Button>
      </Form>
    </div>
  )
}

export default AddNewZoomLive
