import { Button, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { getBanner } from "../../../services/user"
import UploadBanner from "./UploadBanner"

const fakeData = [
  {
    id: "1",
    image:
      "https://truyenthongdps.com/wp-content/uploads/2021/10/instagram-anh-do-an-5.png",
  },
  {
    id: "2",
    image:
      "https://truyenthongdps.com/wp-content/uploads/2021/10/instagram-anh-do-an-5.png",
  },
  {
    id: "3",
    image: "",
  },
]

const BannerManagement = () => {
  const [banners, setBanners] = useState([])
  const [loading, setLoading] = useState(false)

  const handleAdd = () => {
    setBanners((prev) => [...prev, {}])
  }

  useEffect(() => {
    ;(async function () {
      setLoading(true)
      const { data } = await getBanner()
      if (data) {
        setBanners(data.data.length > 0 ? data.data : [{}])
      }
      setLoading(false)
    })()
  }, [])
  return (
    <Spin spinning={loading}>
      <div className="bg-white p-[50px] pt-10">
        <div className="p-4">
          {banners.map((banner, index) => (
            <UploadBanner
              key={banner.id}
              id={banner.id}
              banners={banners}
              setLoading={setLoading}
              linkToUrl={banner.linkToUrl}
              index={index}
              length={banners.length - 1}
              image={banner.bannerUrl}
              setBanners={setBanners}
            />
          ))}
          <div>
            <Button
              type="danger"
              className="!w-[200px] !h-[30px]"
              onClick={handleAdd}
            >
              ＋ 追加
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default BannerManagement
