import { Button, Col, Divider, Form, Input, Modal, Radio, Row } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import { TableLeft } from ".."
import FormSearch from "./FormSearch"
import "../style.scss"
import { GetCollections } from "../../../../../services/product"
import { getAllProductTag } from "../../../../../services/user"
import textAndRules from "../../../../../utils/textAndRules"

const CampaignURL = ({ form }) => {
  const [open, setOpen] = useState(false)
  const [showFormSearch, setShowFormSearch] = useState(false)
  const [showUrl, setShowUrl] = useState(false)

  const [collections, setCollections] = useState(null)
  const [dataOrigin, setDataOrigin] = useState(null)
  const [targetProduct, setTargetProduct] = useState(null)
  const [listProductSelected, setListProductSelected] = useState(null)
  const [isNeedSelect, setIsNeedSelect] = useState(false)

  const handleChange = (e) => {
    if (e.target.value !== 1) {
      setListProductSelected(null)
    }
    setOpen(e.target.value === 1)
    setShowUrl(e.target.value !== 1)
  }

  const getCollections = useCallback(async () => {
    const { response } = await GetCollections()
    if (response.status === 200) {
      setCollections(response.data.collections)
    } else {
      // openCustomNotificationWithIcon(
      //   "error",
      //   "Get List Collections",
      //   "Get Data Collections error"
      // )
    }
  }, [])
  const getProductTags = useCallback(async () => {
    const res = await getAllProductTag({ typeTag: "origin" })
    if (res.data) {
      setDataOrigin(res.data?.productTag)
    }
  }, [])

  useEffect(() => {
    if (open && !collections) {
      getCollections()
    }
    if (open && !dataOrigin) {
      getProductTags()
    }
  }, [open])

  const handleSelectProduct = (e) => {
    form.setFieldsValue({ products: e })
  }
  const handleChangeModal = (e) => {
    setTargetProduct(e.target.value)
    if (e.target.value === "filter_selection") {
      handleSelectProduct(null)
      setShowFormSearch(true)
    } else {
      setShowFormSearch(false)
      setListProductSelected(null)
    }
  }
  console.log("listProductSelected", listProductSelected)
  return (
    <div>
      <Row className="!flex border-t border-custom-border customRadio">
        <TableLeft tag="必須" type="red" title="キャンペーンURL" />
        <Col className="p-5 pr-[159px] flex-1">
          <Form.Item
            name="campaignURL"
            rules={[
              {
                required: true,
                message: textAndRules.pleaseSelect,
              },
            ]}
          >
            <Radio.Group onChange={handleChange}>
              <div className="flex flex-col">
                <Radio
                  value={1}
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  <Form.Item
                    name="products"
                    dependencies={["campaignURL"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            getFieldValue("campaignURL") !== 2 &&
                            getFieldValue("campaignURL")
                          ) {
                            if (value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error("対象商品を選択。"))
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    対象商品を選択
                  </Form.Item>
                </Radio>

                <Radio value={2} className="!mt-2">
                  遷移先URLを設定
                </Radio>
              </div>
            </Radio.Group>
          </Form.Item>
          {showUrl && (
            <div className="w-[50%] mt-[10px] ml-[25px]">
              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: textAndRules.pleaseSelect,
                  },
                  {
                    type: "url",
                    message: "リンクを入力してください。",
                  },
                ]}
              >
                <Input placeholder="URL" />
              </Form.Item>
            </div>
          )}
        </Col>
      </Row>
      <Modal
        title="対象商品を選択"
        okText="削除"
        cancelText="キャンセル"
        centered
        visible={open}
        footer={false}
        width={795}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <div>
          <div className="mt-[6px]">
            <Radio.Group onChange={handleChangeModal} className="w-full">
              <div className="flex flex-col">
                <Radio value={"all_product"}>全商品</Radio>
                <Divider className="!mt-[15px] !mb-[25px]" />
                <Radio value={"filter_selection"}>絞込み選択</Radio>
              </div>
            </Radio.Group>
          </div>
          {showFormSearch && (
            <FormSearch
              collections={collections}
              dataOrigin={dataOrigin}
              handleSelectProduct={handleSelectProduct}
              listProductSelected={listProductSelected}
              setListProductSelected={setListProductSelected}
              isNeedSelect={isNeedSelect}
              setIsNeedSelect={setIsNeedSelect}
            />
          )}
          <div className="mt-10 flex justify-center mb-[76px]">
            <Button
              type="primary"
              className="!w-[329px] !h-[42px] shadow-button text-base font-medium font-notoSansJP"
              onClick={() => {
                if (targetProduct == "filter_selection") {
                  if (
                    !listProductSelected ||
                    listProductSelected?.length == 0
                  ) {
                    handleSelectProduct(null)
                    setIsNeedSelect(true)
                    return
                  } else {
                    handleSelectProduct(listProductSelected)
                  }
                }
                if (targetProduct == "all_product") {
                  handleSelectProduct("all")
                }
                setOpen(false)
              }}
            >
              商品を選択する
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default CampaignURL
