import { Button, Input, Select, Space, Form, Typography } from "antd"
import React, { useContext, useState } from "react"
import { SuperAdSiteContext } from "../.."
import {
  openNotificationDeleteSuccess,
  openNotificationFail,
  openNotificationWarning,
} from "../../../../components/notification"
import { deleteCampaign } from "../../../../services/campaign"

const { Text } = Typography

const HeaderTable = ({
  onSubmit,
  selectedRowKeys,
  setSelectedRowKeys,
  handleGetListCampaign,
}) => {
  const { setCampaignType } = useContext(SuperAdSiteContext)
  const [bulkOperation, setBulkOperation] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [valueInput, setValueInput] = useState(null)
  const deleteOperation = async () => {
    setSubmitting(true)
    if (bulkOperation !== "deleteAll" || selectedRowKeys.length === 0) {
      setSubmitting(false)
      return
    }
    const { response, error } = await deleteCampaign({
      discountIds: selectedRowKeys,
    })

    if (response.message && response.message === "Network Error") {
      setSubmitting(false)
      openNotificationWarning(
        "サーバーへ接続できません。",
        "再度確認してください。",
        "#f81d22"
      )
      return
    }
    if (response.status == 200 || response.status == 201) {
      openNotificationDeleteSuccess("削除に成功しました。", "", "#389e0d")
      setSelectedRowKeys([])
      setSubmitting(false)
      handleGetListCampaign()
      setBulkOperation(null)
    } else {
      setSubmitting(false)
      openNotificationFail("削除に失敗しました。", "", "#f81d22")
    }
  }

  const onFinish = () => {
    if (onSubmit) {
      onSubmit({ keyword: valueInput })
    }
  }

  return (
    <div className="border border-[#F3F3F3] border-b border-b-[rgba(0,0,0,0.06)]">
      <div className="flex flex-col bg-[#fafafa] p-4 ">
        <Text className="text-sm font-medium font-notoSansJP !text-[#595959]">
          絞り込み条件
        </Text>
        <div className="flex items-center mt-2">
          <Input
            placeholder="キーワードで探す"
            className="!w-[402px]"
            value={valueInput}
            onChange={(e) => {
              setValueInput(e.target.value)
            }}
          />
          <Button type="primary" className="!w-[141px] ml-5" onClick={onFinish}>
            検索
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between bg-[#F3F3F3] p-[10px]">
        <Space>
          <div className="text-[#333]">{"一括操作"}</div>
          <Select
            allowClear
            placeholder={"操作を選択"}
            value={bulkOperation}
            onChange={(e) => {
              setBulkOperation(e)
            }}
          >
            <Select.Option value={"deleteAll"}>{"一括削除"}</Select.Option>
          </Select>
          <Button loading={submitting} onClick={deleteOperation}>
            {"実行"}
          </Button>
        </Space>
        <Button
          type="danger"
          className="!w-[200px]"
          onClick={() => setCampaignType("add")}
        >
          ＋ 新規作成
        </Button>
      </div>
    </div>
  )
}

export default HeaderTable
