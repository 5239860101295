import { Button, Col, Form, Image, Row, Upload } from "antd"
import React, { useState } from "react"
import { TableLeft } from "."
import iconFile from "../../../../images/icon_file.svg"
import ImgCrop from "antd-img-crop"
import { openNotificationWarning } from "../../../../components/notification"
import { uploads } from "../../../../services/user"
const CampaignBanner = ({ handleSet }) => {
  const [fileList, setFileList] = useState([])
  const [pass, setPass] = useState(false)

  const beforeUpload = (file, info) => {
    console.log("file", file)
    const isJPG = file.type === "image/jpeg"
    const isJPEG = file.type === "image/jpeg"
    const isGIF = file.type === "image/gif"
    const isPNG = file.type === "image/png"
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      openNotificationWarning(
        "間違ったファイル形式。",
        `JPG、JPEG、GIF、PNGファイルをアップロードしてください。`,
        "#f81d22"
      )

      setPass(false)
      return false
    }
    setPass(true)
    return true
  }

  const handleChange = async (info) => {
    console.log("infoinfo", info)
    let fileList = [...info.fileList]

    if (fileList.length == 0) {
      setFileList([...fileList])
    }
    if (pass) {
      fileList = fileList.slice(-1)
      setFileList(fileList)
    }
  }

  const uploadImageF = async (options) => {
    console.log("options", options)
    const { onSuccess, onError, file, onProgress, category } = options

    const fmData = new FormData()

    fmData.append("files", file)
    try {
      const { response } = await uploads(fmData)
      console.log("resresresres", response)

      if (response?.status == 200) {
        onSuccess("ok", response)

        handleSet("file", response.data?.uploads[0]?.url)
      } else {
        handleSet("file", null)
        onError("fail")
        setFileList([])
      }
    } catch (err) {
      console.log("Eroor: ", err)
      handleSet("file", null)
      onError({ err })
    }
  }

  const normFile = (e) => {
    console.log("Upload event:", e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const checkFileList = (_, value) => {
    console.log("value", value)

    if (Array.isArray(value)) {
      return Promise.reject(new Error("写真はまだアップロードされていません。"))
    }
    if (typeof value == "string") {
      return Promise.resolve()
    }

    return Promise.reject(new Error("画像を選択してください。"))
  }

  return (
    <Row className="!flex border-t border-custom-border">
      <TableLeft tag="必須" type="red" title="キャンペーンバナー" />
      <Col className="p-5 pr-[159px] flex-1 !flex items-center">
        <div>
          <Form.Item
            name="file"
            valuePropName={fileList}
            getValueFromEvent={normFile}
            rules={[
              {
                validator: checkFileList,
              },
            ]}
          >
            <ImgCrop
              aspect={13 / 3}
              grid
              rotate
              modalTitle="画像編集"
              modalOk="選択"
              modalCancel="キャンセル"
            >
              <Upload
                customRequest={uploadImageF}
                fileList={fileList}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                maxCount={1}
                listType="picture"
                onRemove={(e) => {
                  handleSet("file", null)
                }}
              >
                <Button
                  className="!text-[#E60020] !text-13 !font-medium !font-notoSans !border-custom-1 !flex items-center
              rounded-[5px] shadow-button"
                  icon={
                    <Image
                      alt=""
                      src={iconFile}
                      preview={false}
                      className="pr-2"
                    />
                  }
                >
                  画像をアップロード
                </Button>
              </Upload>
            </ImgCrop>
          </Form.Item>
        </div>
      </Col>
    </Row>
  )
}

export default CampaignBanner
