import { Col, Image, Row } from "antd"
import moment from "moment"
import React from "react"

export const RowConfirm = ({ label, children }) => (
  <Row className="border-t border-custom-border !flex">
    <Col className="w-[232px] flex items-center pl-5 bg-[#F3F3F3] text-sm text-custom-34 font-bold font-notoSans">
      {label}
    </Col>
    <Col className="flex items-center pl-5 flex-1 font-notoSans">
      {children}
    </Col>
  </Row>
)

const DetailStep1 = ({ dataCampaign }) => {
  return (
    <div>
      <RowConfirm
        label={"開催期間"}
        children={
          <span className="py-[25px] text-13 font-medium text-black-5">
            {`${moment(dataCampaign.starts_at).format("YYYY/MM/DD")} ～ ${
              dataCampaign.ends_at
                ? moment(dataCampaign.ends_at).format("YYYY/MM/DD")
                : ""
            }`}
          </span>
        }
      />
      <RowConfirm
        label={"キャッチコピー"}
        children={
          <span className="py-5 text-13 font-medium text-black-5">
            {dataCampaign.code}
          </span>
        }
      />
      <RowConfirm
        label={"キャンペーンバナー"}
        children={
          <div className="!w-full pr-[60px] confirmImg py-5">
            <Image
              alt=""
              src={dataCampaign.metadata?.url}
              className="!h-[166.15px] object-cover"
            />
          </div>
        }
      />
      <RowConfirm
        label={"御単価からの割引率"}
        children={
          <span className="py-[28px] text-sm font-medium text-[#595959]">
            {`${dataCampaign.rule?.value}% OFF`}
          </span>
        }
      />
      <RowConfirm
        label={"対象商品"}
        children={
          <span className="py-5 text-13 font-medium text-black-5 break-all ">
            {dataCampaign.metadata?.link ? (
              <div>
                転送先URL:{" "}
                <a href={dataCampaign.metadata?.link} target="_blank">
                  {dataCampaign.metadata?.link}
                </a>
              </div>
            ) : (
              dataCampaign.metadata?.products &&
              (dataCampaign.metadata?.products?.length == 0
                ? "全商品"
                : dataCampaign.metadata?.products?.map((el, index) => {
                    if (index === 0) {
                      return el.label
                    } else {
                      return `, ${el.label}`
                    }
                  }))
            )}
          </span>
        }
      />
      <RowConfirm
        label={"社内メモ"}
        children={
          <p className="py-5 text-13 font-medium text-black-5 break-all !mt-0">
            {dataCampaign.rule?.description}
          </p>
        }
      />
    </div>
  )
}

export default DetailStep1
