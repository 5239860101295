import { Breadcrumb, Tabs } from "antd"
import { Content } from "antd/lib/layout/layout"
import Title from "antd/lib/typography/Title"
import { Link, navigate } from "gatsby"
import React, { createContext, useEffect, useState } from "react"
import BannerManagement from "./bannerManagement"
import CampaignList from "./campaignRegistration"
import AddCampaign from "./campaignRegistration/addCampaign"
import DetailCampaign from "./campaignRegistration/DetailCampaign"
import MasterData from "./masterData"
import ZoomLiveManagement from "./zoomLiveManagement"
import ReviewManagement from "./reviewManagement"
import queryString from "query-string"

const { TabPane } = Tabs

export const SuperAdSiteContext = createContext()

const SuperAdSiteManagement = ({ location }) => {
  const [title, setTitle] = useState("サイト管理")
  const [campaignType, setCampaignType] = useState("list")
  const [idCampaignDetail, setIdCampaignDetail] = useState(null)
  const value = {
    campaignType,
    setCampaignType,

    idCampaignDetail,
    setIdCampaignDetail,
  }
  const query = queryString.parse(location.search)
  useEffect(() => {
    if (query?.page && ["1", "2", "3", "4", "5"].includes(query?.page)) {
      return
    }
    navigate("/super-ad-site-management/?page=1")
  }, [location])
  return (
    <SuperAdSiteContext.Provider value={value}>
      <Content>
        <div
          className="site-layout-background"
          style={{
            minHeight: 360,
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",

              padding: "24px 34px 16px 34px",
            }}
          >
            <Breadcrumb separator={">"}>
              <Breadcrumb.Item route="/home">
                <Link to="/home">HOME</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="!text-[#888888]">
                サイト管理
              </Breadcrumb.Item>
            </Breadcrumb>

            <Title
              level={2}
              className="!text-2xl  !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
            >
              {title}
            </Title>
          </div>

          <Tabs
            activeKey={query?.page}
            onChange={(e) => {
              if (e == 2) {
                setCampaignType("list")
              }
              navigate(`/super-ad-site-management/?page=${e}`)
            }}
            size="large"
            className="companyInfo-tab-pane !px-[50px]"
          >
            <TabPane tab="MV画像管理" key="1">
              <BannerManagement />
            </TabPane>
            <TabPane
              tab={
                <div
                  onClick={() => {
                    setCampaignType("list")
                  }}
                >
                  {"キャンペーン登録・編集"}
                </div>
              }
              key="2"
            >
              {campaignType === "list" && (
                <CampaignList keyPage={query?.page} />
              )}
              {campaignType === "add" && <AddCampaign />}
              {campaignType === "detail" && <DetailCampaign />}
            </TabPane>
            <TabPane tab="口コミ管理" key="3">
              <ReviewManagement keyPage={query?.page} />
            </TabPane>
            <TabPane tab="アレルギー成分マスタ" key="4">
              <MasterData keyPage={query?.page} />
            </TabPane>
            <TabPane tab="ZOOM LIVE管理" key="5">
              <ZoomLiveManagement keyPage={query?.page} />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </SuperAdSiteContext.Provider>
  )
}
export default SuperAdSiteManagement
